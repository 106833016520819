<template>
  <v-select
    v-model="modelValue"
    clearable
    return-object
    :loading="contractStatus === 'pending'"
    :label="$t('finance.contracts.title')"
    :items="contractOptions"
    :readonly="contractOptions.length === 0 || readonly"
    :disabled="contractOptions.length === 0 || disabled"
    item-title="description"
  >
    <template #selection>{{ modelValue?.description }}</template>
    <template #item="{ item, props: itemProps }">
      <v-list-subheader
        v-if="contracts.filter((contract) => contract.hasAssets).findIndex((contract) => contract._id === item.raw._id) === 0"
      >
        {{ $t("asset") }}
      </v-list-subheader>
      <v-list-subheader
        v-else-if="
          showGenericContractTypeHeader &&
          contracts.filter((contract) => !contract.hasAssets).findIndex((contract) => contract._id === item.raw._id) === 0
        "
      >
        {{ $t("generic") }}
      </v-list-subheader>
      <v-list-item v-bind="itemProps" />
    </template>
  </v-select>
</template>

<script setup lang="ts">
import sortBy from "lodash-es/sortBy"
import pick from "lodash-es/pick"

const modelValue = defineModel<{ _id: string; description: string } | null>()
const props = defineProps<{
  /** defines if the ticket is new */
  isNew: boolean
  /** Date for which the contract should be valid */
  date: Date
  /** Organization id - Used to retrieve contracts valid for this organization */
  organization: string
  /** Asset id - Used to retrieve contracts valid for this asset */
  assets?: Array<string>
  /** Supplier id - Used to retrieve contracts valid for this supplier */
  supplier?: string
  // Should add options to retrieve all contracts, also the ones with an asset
  /** TicketType - Used to retrieve contracts valid for this ticket type */
  ticketType: TicketType
  /** disables the input */
  readonly?: boolean
  /** disables the input */
  disabled?: boolean
}>()
const { isNew, date, assets, organization, supplier, ticketType, readonly } = toRefs(props)
const organizationStore = useOrganizationStore()

const { data: contracts, status: contractStatus } = useLazyAsyncData(
  `contracts-${organization.value}-${supplier?.value}-${date.value}-${ticketType.value}-${assets?.value?.join(",")}`,
  async () => {
    if (!organization.value) return []

    const docs = await organizationStore.getAllowedContracts(
      organization.value,
      supplier?.value,
      date.value,
      ticketType.value,
      assets?.value,
    )
    const result = docs
      .filter((contract) => contract._status === ContractStatus.APPROVED)
      .map((contract) => ({
        _id: contract._id,
        description: contract.description,
        hasAssets: contract.assets.length > 0,
      }))

    // Add previously saved contract to the list if it is not already there
    if (modelValue.value?.description) {
      const isContractAvailable = docs.some((contract) => contract._id === modelValue.value!._id)

      if (!isContractAvailable) {
        if (isNew.value) {
          // This can happen when a user selects an asset specific contract
          // and then changes the asset to an asset not included in the contract.
          modelValue.value = null
        } else {
          result.push({
            _id: modelValue.value._id,
            description: modelValue.value.description,
            hasAssets: false,
          })
        }
      }
    }

    // First sort on hasAssets, so contracts with assets are displayed first,
    // then global contracts without assets.
    return sortBy(result, [(contract) => !contract.hasAssets, "description"])
  },
  {
    default: () => [],
    watch: [organization, supplier, assets, date, ticketType],
  },
)

const contractOptions = computed(() => contracts.value.map((contract) => pick(contract, ["_id", "description"])))

// Only show the generic sub header when there is more then 1 type of contract
const showGenericContractTypeHeader = computed(() => contracts.value.filter((contract) => contract.hasAssets).length > 0)
</script>
