<template>
  <div v-if="showAssetField || (isLoggedInOnSupplier && isNew)" class="form-grid">
    <v-autocomplete
      v-if="isLoggedInOnSupplier && !hasInitialOrganization && isNew"
      v-model="internalValue.organization"
      :items="organizations!"
      :label="$t('client') + ' *'"
      :loading="organizationStatus === 'pending'"
      :rules="[(val) => !!val || $t('validation.required')]"
      class="form__field"
      item-title="description"
      item-value="_id"
      menu-icon="arrow_drop_down"
      @update:model-value="onOrganizationUpdated"
    />

    <v-autocomplete
      v-if="showAssetField"
      v-model="internalValue.asset"
      :readonly="isReadOnly"
      :disabled="!hasCreateOrUpdatePermission"
      :loading="assetStatus === 'pending'"
      class="form__field"
      :label="$t('tickets.form.asset') + (partial ? '' : ' *')"
      :items="assetOptions!"
      return-object
      item-title="description"
      item-value="_id"
      :rules="isReadOnly ? [] : [(val) => partial || !!val || $t('validation.required')]"
      :clearable="!isReadOnly"
      :menu-icon="isReadOnly ? '' : 'arrow_drop_down'"
    />
  </div>

  <div class="form-grid">
    <v-combobox
      :model-value="internalValue.description"
      class="form__field"
      :readonly="isReadOnly"
      :disabled="!hasCreateOrUpdatePermission"
      :loading="descriptionsPending || pending"
      :label="$t('tickets.form.description') + (partial ? '' : ' *')"
      :items="descriptionOptions.map((opt) => opt.description)"
      :rules="isReadOnly ? [] : [(val) => partial || !!val || $t('validation.required')]"
      :clearable="!isReadOnly"
      :menu-icon="isReadOnly ? '' : 'arrow_drop_down'"
      @update:model-value="debounceDescription"
    />

    <v-text-field
      v-model="internalValue.date"
      class="form__field"
      :readonly="isReadOnly || isTicketFinished"
      :disabled="!hasCreateOrUpdatePermission"
      type="date"
      :label="$t(`${ticketType?.toLowerCase()}s.field.date`)"
      :clearable="!isReadOnly && !isTicketFinished"
      :loading="pending"
      :hint="!isReadOnly && isTicketFinished ? $t('tickets.field.dateHint') : undefined"
    />

    <v-autocomplete
      v-model="internalValue.supplier"
      class="form__field"
      :readonly="isReadOnly || !isSupplierAllowedToChangeOrganization"
      :disabled="!hasCreateOrUpdatePermission || !isSupplierAllowedToChangeOrganization"
      :loading="suppliersPending || pending"
      :label="$t('tickets.form.supplier') + (partial ? '' : ' *')"
      item-title="description"
      item-value="value"
      :clearable="!isReadOnly"
      :items="supplierOptions!"
      :rules="isReadOnly ? [] : [(val) => partial || !!val || $t('validation.required')]"
      :menu-icon="isReadOnly ? '' : 'arrow_drop_down'"
      @update:model-value="
        () => {
          internalValue.users = []
          internalValue.contract = undefined
          internalValue.articles = undefined
        }
      "
    >
      <template #item="{ item, props: itemProps }">
        <v-list-item v-bind="itemProps" />
        <v-divider v-if="(supplierOptions?.length ?? 0) > 1 && item.value._id === (internalValue.organization ?? currentOrganizationId)" />
      </template>
    </v-autocomplete>

    <v-autocomplete
      :model-value="internalValue.users?.[0]"
      class="form__field"
      :readonly="isReadOnly || !isSupplierAllowedToChangeOrganization"
      :disabled="!hasCreateOrUpdatePermission || !isSupplierAllowedToChangeOrganization"
      :loading="usersPending || pending"
      :label="$t('tickets.form.users')"
      :items="userOptions!"
      :hint="!readonly && !usersPending && !userOptions?.length ? $t('noUsersUnderSupplier') : ''"
      item-title="description"
      item-value="value"
      :clearable="!isReadOnly"
      :menu-icon="isReadOnly ? '' : 'arrow_drop_down'"
      @update:model-value="(user) => (internalValue.users = user ? [user] : [])"
    />

    <ticket-contract-selection
      v-model="internalValue.contract"
      class="form__field"
      :date="dateForContract"
      :organization="internalValue.organization!"
      :assets="assets ?? (internalValue.asset?._id ? [internalValue.asset._id] : undefined)"
      :supplier="internalValue.supplier?._id"
      :ticket-type="ticketType"
      :readonly="isReadOnly || !isSupplierAllowedToChangeContract"
      :disabled="!isSupplierSet || !hasCreateOrUpdatePermission || !isSupplierAllowedToChangeContract"
      :is-new="isNew"
    />

    <slot
      name="contract-articles"
      :contract="internalValue.contract"
      :error-messages="!pending && internalValue.contract ? [$t('invalidContract')] : undefined"
    />

    <v-autocomplete
      v-model="internalValue.invoicePeriod"
      class="form__field"
      :loading="invoicePeriodsPending || pending"
      :label="$t('tickets.field.invoicePeriod')"
      :items="invoicePeriods"
      :readonly="isReadOnly"
      :disabled="
        (!internalValue.invoicePeriod && !readonly && !invoicePeriodsPending && !invoicePeriods?.length) || !hasCreateOrUpdatePermission
      "
      item-title="description"
      item-value="description"
      :clearable="!isReadOnly"
      :menu-icon="isReadOnly ? '' : 'arrow_drop_down'"
    />

    <div v-if="$vuetify.display.smAndUp" class="form__field" />

    <v-textarea
      :model-value="internalValue.remark"
      class="form__field"
      :readonly="isReadOnly"
      :disabled="!hasCreateOrUpdatePermission"
      :label="$t('tickets.field.remark')"
      :loading="pending"
      @update:model-value="debounceRemark"
    />

    <div v-if="$vuetify.display.smAndUp" class="form__field" />

    <v-autocomplete
      v-model="internalValue.tags"
      multiple
      class="form__field"
      :loading="pending || ticketTagsStatus === 'pending'"
      :label="$t('tickets.field.tags')"
      :items="ticketTagOptions"
      return-object
      item-title="description"
      item-value="_id"
      :readonly="isReadOnly"
      :disabled="!hasCreateOrUpdatePermission"
      :menu-icon="isReadOnly ? '' : 'arrow_drop_down'"
    >
      <template #selection="{ item, index }">
        <ticket-tag-badge v-if="index < 2" disable-click :ticket-tag="item.raw" />
        <span v-else-if="index === 2" class="text-caption align-self-center">(+{{ (internalValue.tags?.length ?? 0) - 2 }})</span>
      </template>

      <template #item="{ props: itemProps, item }">
        <v-list-item v-bind="itemProps">
          <template #title>
            <ticket-tag-badge disable-click :ticket-tag="item.raw" />
          </template>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script setup lang="ts">
import cloneDeep from "lodash-es/cloneDeep"
import debounce from "lodash-es/debounce"
import isEqual from "lodash-es/isEqual"
import sortBy from "lodash-es/sortBy"
import pick from "lodash-es/pick"
import { TicketType } from "~~/stores/ticket.store"
import { postProcessStatuses } from "~~/utils/status-helpers"
import type { DocumentDescription } from "~~/stores/types"

const emit = defineEmits(["update:modelValue"])
const props = defineProps<{
  modelValue: Partial<Ticket>
  ticketType: TicketType
  showAssetField?: boolean
  readonly?: boolean
  partial?: boolean
  pending?: boolean
  assets?: Array<string>
}>()
const { modelValue, ticketType, showAssetField, readonly, partial, pending, assets } = toRefs(props)

const assetStore = useAssetStore()
const authStore = useAuthStore()
const actionDescriptionStore = useActionDescriptionStore()
const inspectionDescriptionStore = useInspectionDescriptionStore()
const malfunctionDescriptionStore = useMalfunctionDescriptionStore()
const organizationStore = useOrganizationStore()
const userStore = useUserStore()
const invoicePeriodStore = useInvoicePeriodStore()
const ticketTagStore = useTicketTagStore()
const ticketStore = useTicketStore()
const supplierStore = useSupplierStore()

const { isSupplier, currentOrganization: currentOrganizationId, sourceOrganization } = storeToRefs(authStore)
const { currentUser } = storeToRefs(userStore)
const { inspectionTicketTypes } = storeToRefs(ticketStore)
const { isLoggedInOnSupplier } = storeToRefs(supplierStore)

const { hasScope } = authStore

const hasInitialOrganization = !!modelValue.value.organization
const isSupplierSet = computed(() => !!internalValue.value.supplier)
const organizationHasOwnershipOverTicket = computed(() => {
  if (!currentUser.value || !internalValue.value.organization) return false
  // if no supplier is selected, the ticket is new
  if (!internalValue.value.supplier) return true
  if (currentUser.value.userRole === UserRole.SUPER_ADMIN) return true
  // if the user has normal admin rights, they can bypass the ownership check.
  if (hasScope(internalValue.value.organization, AuthScope.ADMIN)) return true

  return (
    internalValue.value.organization === currentUser.value.organization ||
    internalValue.value.supplier?._id === currentUser.value.organization
  )
})

const internalValue = ref<Partial<Ticket>>({
  ...cloneDeep(modelValue.value),
})

const isNew = computed(() => !internalValue.value._id)
const hasOrganization = computed(() => !!internalValue.value.organization)
const hasCreateOrUpdatePermission = computed(() => {
  if (!hasOrganization.value) return false

  const hasPermission = (scope: AuthScope) => hasScope(internalValue.value.organization!, scope)

  switch (ticketType.value) {
    case TicketType.ACTION:
      return isNew.value ? hasPermission(AuthScope.CAN_CREATE_ACTIONS) : hasPermission(AuthScope.CAN_UPDATE_ACTIONS)
    case TicketType.NEN3140:
    case TicketType.NEN1010:
    case TicketType.INSPECTION:
      return isNew.value ? hasPermission(AuthScope.CAN_CREATE_INSPECTIONS) : hasPermission(AuthScope.CAN_UPDATE_INSPECTIONS)
    case TicketType.MALFUNCTION:
      return isNew.value ? hasPermission(AuthScope.CAN_CREATE_MALFUNCTIONS) : hasPermission(AuthScope.CAN_UPDATE_MALFUNCTIONS)
    default:
      return false
  }
})

const isReadOnly = computed(() => {
  // Disable fields when readonly is set,
  // the user is missing permissions
  // or organization is not set (ex: supplier with multiple organizations)
  return readonly.value || !hasCreateOrUpdatePermission.value || !hasOrganization.value || !organizationHasOwnershipOverTicket.value
})

// When the supplier id is the same as the organization we know this ticket belongs to the organization and not a supplier
const isOrganizationTicket = computed(
  () => !!internalValue.value.organization && internalValue.value.supplier?._id === internalValue.value.organization,
)

// When the contract is set by the organization, the supplier should not be able to change it
const isSupplierAllowedToChangeContract = computed(() => !isSupplier.value || !isOrganizationTicket.value)

// When the supplier and user are set by the organization, the supplier should not be able to change it
const isSupplierAllowedToChangeOrganization = computed(() => !isSupplier.value || !isSupplierSet.value || !isOrganizationTicket.value)

const dateForContract = computed(() => {
  if (internalValue.value.startAt) {
    return new Date(internalValue.value.startAt)
  } else if (internalValue.value.date) {
    return new Date(internalValue.value.date)
  } else {
    return new Date()
  }
})

const isTicketFinished = computed(() => {
  const status = internalValue.value.status
  return status && postProcessStatuses.includes(status)
})

const { data: organizations, status: organizationStatus } = useLazyAsyncData(
  `organizations:${isSupplier.value ? sourceOrganization.value : "suppliers"}`,
  async (): Promise<Array<Organization>> => {
    const organizations = isSupplier.value
      ? await organizationStore.getOrganizationsForSupplier(sourceOrganization.value!)
      : await organizationStore.getOrganizations()

    const sortedOrganizations = sortBy(organizations, "description")

    // If supplier, only return the organizations for which the supplier has create permissions
    if (isSupplier.value) {
      const scope =
        ticketType.value === TicketType.ACTION
          ? AuthScope.CAN_CREATE_ACTIONS
          : inspectionTicketTypes.value.includes(ticketType.value)
            ? AuthScope.CAN_CREATE_INSPECTIONS
            : AuthScope.CAN_CREATE_MALFUNCTIONS

      return sortedOrganizations.filter((org) => hasScope(org._id, scope))
    }

    return sortedOrganizations
  },
  {
    default: () => [] as Array<Organization>,
    watch: [isSupplier],
  },
)

const {
  data: assetOptions,
  status: assetStatus,
  refresh: refreshAssets,
} = useLazyAsyncData(
  async () => {
    if (isReadOnly.value || !hasOrganization.value || !showAssetField?.value) {
      return []
    }

    const query = {
      query: {
        organization: internalValue.value.organization,
      },
    }

    const assets = await assetStore.getManyLookup(query)

    // Combine the key and description
    const lookupAssets = assets.map((asset) => ({
      _id: asset._id,
      description: `${asset.key} | ${asset.description}`,
    }))

    return sortBy(lookupAssets, "description")
  },
  {
    default: () => [] as Array<DocumentDescription>,
    watch: [hasOrganization, isReadOnly, showAssetField],
  },
)

const {
  data: descriptionOptions,
  pending: descriptionsPending,
  refresh: refreshDescriptions,
} = useLazyAsyncData(
  `descriptions-${ticketType.value?.toLocaleLowerCase()}`,
  async () => {
    if (isReadOnly.value || !hasOrganization.value) return []

    const organization = internalValue.value.organization

    switch (ticketType.value) {
      case TicketType.ACTION:
        return await actionDescriptionStore.getItems(false, organization)
      case TicketType.INSPECTION:
        return await inspectionDescriptionStore.getItems(false, organization)
      case TicketType.MALFUNCTION:
        return await malfunctionDescriptionStore.getItems(false, organization)
      default:
        return []
    }
  },
  { default: () => [] as Array<ListValue>, watch: [hasOrganization, isReadOnly, ticketType] },
)

const { data: ticketTagOptions, status: ticketTagsStatus } = useAsyncData(
  `ticket-tags:${currentOrganizationId.value}:${ticketType.value}`,
  async () => {
    if (isReadOnly.value) return []
    return ticketTagStore.getTicketTags(false, currentOrganizationId.value)
  },
  { default: () => [] as Array<TicketTag>, watch: [isReadOnly, ticketType] },
)

const {
  data: supplierOptions,
  pending: suppliersPending,
  refresh: refreshSuppliers,
} = useLazyAsyncData(
  "supplier-options",
  async () => {
    if (isReadOnly.value) return []

    const organization = internalValue.value.organization ?? currentOrganizationId.value
    if (!organization) return []

    const [supplierScopes, currentOrganization] = await Promise.all([
      organizationStore.getSuppliers(organization),
      organizationStore.getOrganization(organization),
    ])

    if (supplierScopes) {
      const options = sortBy(
        supplierScopes.map((template) => ({
          value: pick(template.supplier, ["_id", "description"]),
          description: template.supplier?.description,
        })),
        "description",
      )

      if (currentOrganization && !isSupplier.value) {
        // Add organization to the top of the list
        options.unshift({ value: pick(currentOrganization, ["_id", "description"]), description: currentOrganization.description })
      } else if (currentOrganization && isSupplier.value) {
        // Check if current organization has a supplier that corresponds with the organization of the current user
        const supplierTemplate = supplierScopes.find(({ supplier }) => supplier._id === currentUser.value?.organization)
        if (supplierTemplate) {
          return [
            {
              value: pick(supplierTemplate.supplier, ["_id", "description"]),
              description: supplierTemplate.supplier.description,
            },
          ]
        } else {
          return []
        }
      }

      return options
    }
  },
  { default: () => [] as Array<{ _id: string; description: string }>, watch: [isReadOnly] },
)

const {
  data: userOptions,
  pending: usersPending,
  refresh: refreshInspectors,
} = useLazyAsyncData(
  "user-options",
  async () => {
    if (isReadOnly.value) return []
    if (!internalValue.value.organization || !internalValue.value.supplier) {
      return []
    }

    // in order for suppliers to also get the right results, we have to manually check the scope, instead of just calling the `scopes` array.
    // this is because the `scopes` array is based on the current user's organization, and not the supplier's organization.
    const canAssignOtherUsers = await authStore.checkScope(
      currentUser.value!._id,
      AuthScope.CAN_ASSIGN_USERS,
      internalValue.value.supplier!._id,
    )
    const isFromSelectedSupplier = currentUser.value?.organization === internalValue.value.supplier?._id
    const isFromTicketOrganization = currentUser.value?.organization === internalValue.value.organization

    const supplierUserCannotAssign = canAssignOtherUsers.allowed === false && !authStore.isSuperAdmin
    const customerUserCannotAssign = !hasScope(internalValue.value.organization!, AuthScope.CAN_ASSIGN_USERS)

    const isSupplierAssignmentDisallowed = !isFromTicketOrganization && isFromSelectedSupplier && supplierUserCannotAssign
    const isCustomerAssignmentDisallowed = isFromTicketOrganization && isFromSelectedSupplier && customerUserCannotAssign

    if (isSupplierAssignmentDisallowed || isCustomerAssignmentDisallowed) {
      return [
        {
          value: {
            _id: currentUser.value?._id,
            description: `${currentUser.value?.firstName} ${currentUser.value?.lastName}`.trim(),
          },
          description: `${currentUser.value?.firstName} ${currentUser.value?.lastName}`.trim(),
        },
      ]
    }

    const assignableUsers = await userStore.getAssignableUsers(internalValue.value.supplier._id)

    // add current user if he has access to the organization but is not assignable
    if (
      currentUser.value?.organization === internalValue.value.supplier._id &&
      !hasScope(internalValue.value.organization!, AuthScope.CAN_BE_ASSIGNED)
    ) {
      assignableUsers.push({
        ...pick(currentUser.value, ["_id", "email", "hasAccess", "userRole", "firstName", "lastName", "organization"]),
        description: `${currentUser.value?.firstName} ${currentUser.value?.lastName}`.trim(),
      })
    }

    return sortBy(
      assignableUsers.map((user) => ({
        value: { _id: user._id, description: `${user.firstName} ${user.lastName}`.trim() },
        description: `${user.firstName} ${user.lastName}`.trim(),
      })),
      "description",
    )
  },
  { default: () => [] as Array<{ value: DocumentDescription; description: string }>, watch: [isReadOnly] },
)

const {
  data: invoicePeriods,
  pending: invoicePeriodsPending,
  refresh: refreshInvoicePeriods,
} = useLazyAsyncData(
  async () => {
    if (isReadOnly.value || !hasOrganization.value) return []

    const query = {
      pagination: false,
      query: {
        // We need to filter on organization here, otherwise suppliers will
        // receive all invoice periods from all organizations they have access to
        organization: internalValue.value.organization,
      },
      select: {
        _id: 1,
        description: 1,
      },
      sort: {
        createdAt: -1,
      },
    }

    const { docs } = await invoicePeriodStore.getByPage(query)
    return docs
  },
  { default: () => [] as Array<ListValue>, watch: [isReadOnly] },
)

const debounceDescription = debounce((value: string) => {
  internalValue.value = {
    ...internalValue.value,
    description: value,
  }
}, DEFAULT_DEBOUNCE_TIME)

const debounceRemark = debounce((value: string) => {
  internalValue.value = {
    ...internalValue.value,
    remark: value,
  }
}, DEFAULT_DEBOUNCE_TIME)

const cleanTicketValue = (
  rawTicket: Partial<Action> | Partial<Malfunction> | Partial<Inspection> | Partial<Nen3140> | Partial<Nen1010>,
): Partial<Action> | Partial<Malfunction> | Partial<Inspection> | Partial<Nen3140> | Partial<Nen1010> => ({
  // only keep properties that actually get modified by the form
  _id: rawTicket._id,
  asset: rawTicket.asset,
  description: rawTicket.description,
  date: toIsoDate(rawTicket.date) || undefined,
  supplier: rawTicket.supplier,
  users: rawTicket.users,
  contract: rawTicket.contract,
  invoicePeriod: rawTicket.invoicePeriod,
  remark: rawTicket.remark,
  organization: rawTicket.organization,
  articles: rawTicket.articles,
  tags: rawTicket.tags,
})

const onOrganizationUpdated = async () => {
  internalValue.value.asset = undefined
  internalValue.value.contract = undefined
  internalValue.value.articles = undefined

  refreshAssets()
  refreshDescriptions()
  refreshSuppliers()
  refreshInvoicePeriods()
}

watch(
  () => cleanTicketValue(modelValue.value),
  async (cleanModelValue) => {
    if (!cleanModelValue) return

    if (!isEqual(cleanModelValue.supplier, internalValue.value.supplier)) {
      await refreshSuppliers()
    }

    internalValue.value = cleanTicketValue(cleanModelValue)
  },
  { deep: true, immediate: true },
)

watch(
  internalValue,
  () => {
    if (isEqual(cleanTicketValue(internalValue.value), cleanTicketValue(modelValue.value))) return

    emit("update:modelValue", {
      ...modelValue.value,
      ...cleanTicketValue(internalValue.value),
    })
  },
  { deep: true },
)

watch(
  () => internalValue.value.supplier,
  (val, oldVal) => {
    if (!isEqual(val, oldVal)) refreshInspectors()
  },
)

watch(
  ticketType,
  (val, oldVal) => {
    if (!isEqual(val, oldVal)) refreshDescriptions()
  },
  { deep: true },
)
</script>
